import PropTypes from 'prop-types'
import React from 'react'

import loadable from '@loadable/component'

const Form = loadable(() => import('./Form'))

const PageBuilderContent = ({ block, raw, index }, props) => (
  <section
    className={`container md:px-4 flex mx-auto xl:px-24 py-8 md:py-12 relative ${
      index === 0 ? `mt-16 md:mt-20` : ``
    }`}
  >
    <div className="mx-auto">
      <Form testType="General Enquiry" />
    </div>
  </section>
)

PageBuilderContent.propTypes = {
  block: PropTypes.object,
  raw: PropTypes.object,
  index: PropTypes.number,
}

PageBuilderContent.defaultProps = {
  block: {},
  raw: {},
  index: 0,
}

export default PageBuilderContent
